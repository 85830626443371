/*------------------------------------------------------------------
Created on   : 17/09/2021.
Template Name   : Deski - Saas & Software React Template
Version      : 1.0.
Developed by : (ib-themes21@gmail.com) / (https://themeforest.net/user/ib-themes)
Primary use:  ib-themes



-------------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&display=swap');

/*----TextFont-------*/
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&family=Roboto:wght@300;400;500;700&family=Rubik:wght@300;400;500&display=swap");
/*----Gilroy-------*/
@import url("./fonts/gilroy/fonts.css");
/*----Gorodita-------*/
@import url("./fonts/gordita/stylesheet.css");
/*----Recoleta-------*/
@import url("./fonts/recoleta/stylesheet.css");
/*----Flaticon-------*/
@import url("./fonts/icon/font/flaticon.css");
/*----FontAwesome-------*/
@import url("./fonts/font-awesome/css/font-awesome.min.css");
/*----GilroyFont-------*/
@import url("./fonts/gilroy/fonts.css");
// react modal video
@import "node_modules/react-modal-video/scss/modal-video.scss";
// bootstrap css
@import "bootstrap/dist/css/bootstrap.min.css";

@import "./scss/animation";
@import "./scss/slick";
@import "./scss/modal";
@import "./scss/404";
@import "./scss/framework";
@import "./scss/mega-menu";
@import "./scss/preview";
@import "./scss/style";
@import "./scss/mobile-menu";
@import "./scss/custom-reactify";
@import "./scss/dark";
@import "./scss/responsive";
